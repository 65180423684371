import React from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import CheckboxDescription from './CheckboxDescription';
import stylesheet from './Checkbox.css';

type Props = {
  className?: string;
  description?: React.ReactNode;
  id?: string;
  isLabelVisible?: boolean;
  label?: React.ReactNode;
  onChange: $TSFixMeFunction;
  value: boolean;
};

/**
 * A wrapper around the native browser `<input type="checkbox" />`, as well as a `<label>` and other sensible defaults.
 *
 * ## Usage
 * Checkbox can be used stand-alone:
 * ```js
 * import Checkbox from '@mc/Input/Checkbox';
 *
 * function StandaloneExample() {
 *   const [foo, setFoo] = useState('');
 *   return (
 *     <Checkbox onChange={setFoo} value={foo} label="Foo" />
 *   />
 *   );
 * }
 *```
 *
 * Or as the component of a `FormField` (which must be nested inside `<Form>`), in which the required values will automatically be supplied from the `Form` context.:
 * ```js
 * import { FormField } from '@mc/components/Form';
 * import Checkbox from '@mc/Input/Checkbox';
 *
 * function FormFieldExample() {
 *   return (
 *     <FormField component={Checkbox} />
 *   />
 *   );
 * }
 * ```
 */
function Checkbox({
  id,
  // The following prop is not consumed inside `Checkbox`, but
  // provided by a generic interface. We don't need to account
  // for PropTypes or usage, so we'll just disable linting here.
  // eslint-disable-next-line react/prop-types, no-unused-vars
  // @ts-expect-error TS2339
  isInvalid,
  label,
  onChange = () => {},
  // This prop is also not consumed, but destructured to omit
  // it from downstream `props` usages.
  // eslint-disable-next-line react/prop-types, no-unused-vars
  // @ts-expect-error TS2339
  secondaryLabel,
  description,
  value = false,
  className,
  isLabelVisible = true,
  ...props
}: Props) {
  const autoId = useId();
  const _id = id || autoId;

  return (
    <label className={cx(stylesheet.container, className)}>
      <input
        {...props}
        type="checkbox"
        id={_id}
        className={stylesheet.checkbox}
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
      />
      {isLabelVisible ? (
        <span
          id={`${_id}-label`}
          className={cx(stylesheet.labelText, {
            [stylesheet.withDescription]: !!description,
          })}
        >
          {label}
        </span>
      ) : (
        <span className="wink-visually-hidden">
          <label htmlFor={_id}>{label}</label>
        </span>
      )}
    </label>
  );
}

Checkbox.Description = CheckboxDescription;

export default Checkbox;
