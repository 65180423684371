import React from 'react';

import stylesheet from './Checkbox.css';

type Props = {
  children: React.ReactNode;
  id: string;
};

const CheckboxDescription = ({ id, children }: Props) => {
  return (
    <div id={id} className={stylesheet.description}>
      {children}
    </div>
  );
};

export default CheckboxDescription;
