import React from 'react';
import CharacterCounter from './CharacterCounter';
import stylesheet from './InputLabel.css';

type Props = {
  children?: string | React.ReactNode | $TSFixMe[];
  hasFocus?: boolean;
  htmlFor: string;
  maxLength?: number;
  renderCharacterCount?: $TSFixMeFunction;
  secondaryLabel?: string | React.ReactNode;
  shouldShowCharacterCount?: boolean;
  value?: $TSFixMe;
};

function InputLabel({
  children,
  hasFocus = false,
  htmlFor,
  maxLength,
  renderCharacterCount,
  secondaryLabel = null,
  shouldShowCharacterCount = true,
  value,
}: Props) {
  return (
    <div className={stylesheet.label}>
      <label htmlFor={htmlFor} className={stylesheet.primary}>
        {children}
      </label>
      <span className={stylesheet.secondary}>
        {typeof value === 'string' &&
        hasFocus &&
        shouldShowCharacterCount &&
        maxLength ? (
          <CharacterCounter currentLength={value.length} maxLength={maxLength}>
            {renderCharacterCount}
          </CharacterCounter>
        ) : (
          secondaryLabel
        )}
      </span>
    </div>
  );
}

export default InputLabel;
