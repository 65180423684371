import React from 'react';
import cx from 'classnames';
import stylesheet from './Button.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  clearMargin?: boolean;
  disabled?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  isLoading?: boolean;
  isNormalWeightText?: boolean;
  onClick?: $TSFixMeFunction;
  type: 'dropdownTrigger' | 'important' | 'primary' | 'secondary' | 'tertiary';
};

/**
 * Wrapper around the HTML button element.
 *
 * @deprecated Use the Button component from mc/wink instead.
 *
 */
const Button = React.forwardRef<$TSFixMe, Props>(
  (
    {
      children,
      className,
      clearMargin,
      htmlType = 'button',
      isLoading = false,
      isNormalWeightText = false,
      onClick,
      type,
      ...props
    },
    ref,
  ) => {
    return (
      // The react/button-has-type rule does not like references
      // and will complain about this usage, despite it being okay.
      <button
        ref={ref}
        disabled={isLoading}
        // eslint-disable-next-line react/button-has-type
        type={htmlType}
        onClick={onClick}
        className={cx(
          stylesheet.container,
          {
            [stylesheet.important]: type === 'important',
            [stylesheet.primary]: type === 'primary',
            [stylesheet.secondary]: type === 'secondary',
            [stylesheet.tertiary]: type === 'tertiary',
            [stylesheet.dropdownTrigger]: type === 'dropdownTrigger',
            [stylesheet.clearMargin]: clearMargin === true,
            [stylesheet.normalWeight]:
              type === 'tertiary' && isNormalWeightText,
            [stylesheet.loading]: isLoading,
          },
          className,
        )}
        {...props}
      >
        {children}
      </button>
    );
  },
);

export default Button;
