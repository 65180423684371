import React from 'react';
import {
  MenuFirstIcon,
  MenuLeftIcon,
  MenuRightIcon,
  MenuLastIcon,
} from '@mc/wink-icons';
import { ClusterLayout, IconButton, Text } from '@mc/wink';
import { useText } from './useText';
import stylesheet from './index.css';

const calcPagination = ({ offset, limit, totalNumberOfItems }: $TSFixMe) => {
  const firstPageOffset = 0;
  const prevPageOffset =
    offset - limit > firstPageOffset ? offset - limit : firstPageOffset;
  const baseLastPage = totalNumberOfItems / limit;
  const lastPageOffset =
    baseLastPage % 1
      ? Math.floor(baseLastPage) * limit
      : (baseLastPage - 1) * limit;
  const nextPageOffset =
    offset + limit < lastPageOffset ? offset + limit : lastPageOffset;

  const isPastDisabled = offset === firstPageOffset;
  const isFutureDisabled = offset === lastPageOffset;

  const startRange = offset + 1;
  const endRange =
    offset + limit > totalNumberOfItems ? totalNumberOfItems : offset + limit;

  return {
    firstPageOffset,
    prevPageOffset,
    nextPageOffset,
    lastPageOffset,
    isPastDisabled,
    isFutureDisabled,
    startRange,
    endRange,
  };
};

type BasicPaginatorProps = {
  offset: number;
  paginationState: {
    limit: number;
    totalNumberOfItems: number;
  };
  setOffset: $TSFixMeFunction;
};

const BasicPaginator = ({
  offset,
  paginationState,
  setOffset,
}: BasicPaginatorProps) => {
  const { limit, totalNumberOfItems } = paginationState;

  const {
    firstPageOffset,
    prevPageOffset,
    nextPageOffset,
    lastPageOffset,
    startRange,
    endRange,
    isPastDisabled,
    isFutureDisabled,
  } = calcPagination({ offset, limit, totalNumberOfItems });

  const handleFirstPage = () => {
    setOffset(firstPageOffset);
  };

  const handlePreviousPage = () => {
    setOffset(prevPageOffset);
  };

  const handleNextPage = () => {
    setOffset(nextPageOffset);
  };

  const handleLastPage = () => {
    setOffset(lastPageOffset);
  };

  const {
    firstPageLabel,
    previousPageLabel,
    nextPageLabel,
    lastPageLabel,
    paginationDetails,
  } = useText({
    startRange,
    endRange,
    // @ts-expect-error TS2322
    totalCount: totalNumberOfItems,
  });

  return (
    <ClusterLayout
      alignItems="center"
      gap={4}
      justifyContent="flex-start"
      className={stylesheet.basicPaginatorClusterLayout}
    >
      <Text appearance="medium">{paginationDetails}</Text>
      <span>
        <IconButton
          disabled={isPastDisabled}
          label={firstPageLabel}
          onClick={handleFirstPage}
          icon={<MenuFirstIcon />}
        />
        <IconButton
          disabled={isPastDisabled}
          label={previousPageLabel}
          onClick={handlePreviousPage}
          icon={<MenuLeftIcon />}
        />
        <IconButton
          disabled={isFutureDisabled}
          label={nextPageLabel}
          onClick={handleNextPage}
          icon={<MenuRightIcon />}
        />
        <IconButton
          disabled={isFutureDisabled}
          label={lastPageLabel}
          onClick={handleLastPage}
          icon={<MenuLastIcon />}
        />
      </span>
    </ClusterLayout>
  );
};

export default BasicPaginator;
