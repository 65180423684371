import { useContext } from 'react';
import { getNestedValue } from '@mc/fn/nestedValue';
import FormContext from './FormContext';

export default function useFormField(name: $TSFixMe) {
  const formContext = useContext(FormContext);
  // @ts-expect-error TS2339
  const { formId, values, touched, errors, setValue } = formContext;
  return {
    id: `${formId}-${name}`,
    value: getNestedValue(values, name),
    // @ts-expect-error TS7053
    error: errors[name] || null,
    // @ts-expect-error TS7053
    isValid: !errors[name],
    isTouched: touched.includes(name),
    onChange: setValue(name),
    formContext,
  };
}
