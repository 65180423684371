import React, { useState, forwardRef } from 'react';
import classNames from 'classnames';
import useId from '@mc/hooks/useId';
import InputLabel from './InputLabel';
import stylesheet from './InputText.css';

type Props = {
  className?: string;
  id?: string;
  isInvalid?: boolean;
  isLabelVisible?: boolean;
  isStrictMaxLength?: boolean;
  label: string | React.ReactNode;
  maxLength?: number;
  onChange: $TSFixMeFunction;
  prefix?: React.ReactNode;
  renderCharacterCount?: $TSFixMeFunction;
  secondaryLabel?: React.ReactNode;
  suffix?: React.ReactNode;
  type?:
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'date';
  value?: string | number;
};

/**
 * A wrapper around the native browser `<input />`, as well as a `<label>` and other sensible defaults.
 *
 * ## Usage
 * InputText can be used stand-alone:
 * ```js
 * import InputText from '@mc/Input/InputText';
 *
 * function StandaloneExample() {
 *   const [foo, setFoo] = useState('');
 *   return (
 *     <InputText onChange={setFoo} value={foo} label="Foo" />
 *   />
 *   );
 * }
 *```
 *
 * Or as the component of a `FormField` (which must be nested inside `<Form>`), in which the required values will automatically be supplied from the `Form` context.:
 * ```js
 * import { FormField } from '@mc/components/Form';
 * import InputText from '@mc/Input/InputText';
 *
 * function FormFieldExample() {
 *   return (
 *     <FormField component={InputText} />
 *   />
 *   );
 * }
 * ```
 */
const InputText = forwardRef<$TSFixMe, Props>(
  (
    {
      className,
      id,
      isInvalid,
      isLabelVisible = true,
      isStrictMaxLength = true,
      label,
      prefix,
      onChange = () => {},
      secondaryLabel,
      suffix,
      maxLength,
      renderCharacterCount,
      type = 'text',
      value = '',
      ...props
    },
    ref,
  ) => {
    const [hasFocus, setHasFocus] = useState(false);
    const autoId = useId();
    const _id = id || autoId;

    return (
      <div className={classNames(stylesheet.container, className)}>
        {isLabelVisible ? (
          <InputLabel
            htmlFor={_id}
            // @ts-expect-error TS2322
            label={label}
            secondaryLabel={secondaryLabel}
            value={value}
            renderCharacterCount={renderCharacterCount}
            maxLength={maxLength}
            hasFocus={hasFocus}
          >
            {label}
          </InputLabel>
        ) : (
          <span className="wink-visually-hidden">
            <label htmlFor={_id}>{label}</label>
          </span>
        )}
        <div className={stylesheet.inputWrapper}>
          {prefix && <span className={stylesheet.inputPrefix}>{prefix}</span>}
          <input
            ref={ref}
            type={type}
            className={classNames(stylesheet.input, {
              [stylesheet.invalid]: isInvalid,
            })}
            id={_id}
            value={value}
            maxLength={isStrictMaxLength ? maxLength : undefined}
            onChange={(event) => onChange(event.target.value)}
            onBlur={() => setHasFocus(false)}
            onFocus={() => setHasFocus(true)}
            {...props}
          />
          {suffix && <span className={stylesheet.inputSuffix}>{suffix}</span>}
        </div>
      </div>
    );
  },
);

export default InputText;
