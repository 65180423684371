import React, { forwardRef } from 'react';
import cx from 'classnames';

import isValidElementType from '@mc/fn/isValidElementType';
import useFormField from './useFormField';
import stylesheet from './FormField.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
  component: $TSFixMeFunction | $TSFixMe;
  description?: React.ReactNode;
  descriptionClassName?: string;
  id?: string;
  label: React.ReactNode;
  name: string;
  optionalFieldIndicator?: string;
  required?: boolean;
  requiredFieldIndicator?: string;
  type?: string;
};

/**
 * @deprecated Use the Form component from mc/wink instead.
 *
 */
const FormField = forwardRef<$TSFixMe, Props>(
  (
    {
      name,
      className,
      component: InputComponent,
      children,
      description,
      id,
      required = false,
      descriptionClassName,
      ...props
    },
    ref,
  ) => {
    const Description = InputComponent.Description || 'div';
    const {
      id: defaultId,
      value,
      onChange,
      error,
      isValid,
      isTouched,
      formContext,
    } = useFormField(name);

    const fieldId = id || defaultId;
    const describedBy = [];
    const shouldShowAsInvalid =
      (isTouched || (formContext as $TSFixMe).hasSubmitted) && !isValid;

    if (shouldShowAsInvalid) {
      describedBy.push(`${fieldId}-error`);
    }

    if (description) {
      describedBy.push(`${fieldId}-description`);
    }

    const secondaryLabel = required
      ? (formContext as $TSFixMe).requiredFieldIndicator
      : (formContext as $TSFixMe).optionalFieldIndicator;

    const inputProps = {
      id: fieldId,
      'aria-describedby': describedBy.join(' ') || undefined,
      isInvalid: shouldShowAsInvalid,
      description,
      required,
      ref,
      secondaryLabel,
      ...props,
      name,
      value,
      onChange,
    };

    return (
      <div className={cx(stylesheet.fieldWrapper, className)}>
        {isValidElementType(InputComponent) ? (
          <InputComponent {...inputProps} />
        ) : (
          InputComponent(inputProps)
        )}

        {description && (
          <Description
            id={`${fieldId}-description`}
            className={cx(stylesheet.description, descriptionClassName)}
          >
            {description}
          </Description>
        )}
        {error && shouldShowAsInvalid && (
          <div
            id={`${fieldId}-error`}
            className={stylesheet.invalidError}
            data-form-error
          >
            {error}
          </div>
        )}
        {children}
      </div>
    );
  },
);

export default FormField;
