import React, { useContext } from 'react';
import Button from '../Button';
import FormContext from './FormContext';

type Props = {
  disabled?: boolean;
  disabledWhenInvalid?: boolean;
  isLoading?: boolean;
};

function SubmitButton({
  isLoading,
  disabledWhenInvalid = true,
  ...props
}: Props) {
  // @ts-expect-error TS2339
  const { isSubmitting, isValid } = useContext(FormContext);
  const isDisabledBecauseInvalid = disabledWhenInvalid && !isValid;
  return (
    // @ts-expect-error TS2739
    <Button
      {...props}
      isLoading={isSubmitting || isLoading}
      disabled={isSubmitting || isDisabledBecauseInvalid || props.disabled}
      htmlType="submit"
    />
  );
}

export default SubmitButton;
