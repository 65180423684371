import { useTranslateMessage } from '@mc/internationalization/translationUtils';

export const useText = ({
  startRange = 0,
  endRange = '',
  totalCount = '',
} = {}) => {
  return {
    // @ts-expect-error TS2345
    firstPageLabel: useTranslateMessage({
      id: 'surveys.ui_controls.basic_paginator.first_page',
      defaultMessage: 'First page',
    }),
    // @ts-expect-error TS2345
    previousPageLabel: useTranslateMessage({
      id: 'surveys.ui_controls.basic_paginator.previous_page',
      defaultMessage: 'Previous page',
    }),
    // @ts-expect-error TS2345
    nextPageLabel: useTranslateMessage({
      id: 'surveys.ui_controls.basic_paginator.next_page',
      defaultMessage: 'Next page',
    }),
    // @ts-expect-error TS2345
    lastPageLabel: useTranslateMessage({
      id: 'surveys.ui_controls.basic_paginator.last_page',
      defaultMessage: 'Last page',
    }),
    // @ts-expect-error TS2345
    paginationDetails: useTranslateMessage({
      id: 'surveys.ui_controls.basic_paginator.pagination_details',
      defaultMessage: 'Surveys {startRange} – {endRange} of {totalCount}',
      values: {
        startRange,
        endRange,
        totalCount,
      },
    }),
  };
};
